import { Component } from '@angular/core';
import { LocalRecordingService } from './services/local-recording.service';
import {IframeHandlerService} from './services/iframe-handler.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'meetings-frontend';

  constructor(private localRecordingService: LocalRecordingService, private iframeHandlerService: IframeHandlerService){
    //the iframe handler service is added here so in future, we can use it to listen for messages from a perent hosting vcircle in in iframe
  }

  get recordingFilename(){
    return this.localRecordingService.recordingFileHandle?.name;
  }

  get recordingProcessingProgress(){
    return this.localRecordingService.recordingProcessingProgress;
  }
}
