import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterContentChecked, Host, Optional, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Participant } from 'src/app/models/Participant';
import { MeetingSettings } from 'src/app/models/MeetingSettings';
import { MeetingComponent } from '../meeting/meeting.component';
import { SelectMediaComponent } from '../select-media/select-media.component';
import { AudioProcessingService } from '../../services/audio-processing.service'
import { MeetingHandlerService } from 'src/app/services/meeting-handler.service';

@Component({
  selector: 'app-participant-video-setup-view',
  templateUrl: './participant-video-setup-view.component.html',
  styleUrls: ['./participant-video-setup-view.component.scss']
})
export class ParticipantVideoSetupViewComponent implements OnInit, OnDestroy {

  videoEnabled = false;
  audioEnabled = false;
  videoStream: MediaStream;
  audioStream: MediaStream;
  @Input() inMeeting = false;
  //@Input() participant: Participant = new Participant();//username will be empty if not using this component during the meeting
  @ViewChild('video', { static: false }) private video: ElementRef;
  @ViewChild('audio', { static: false }) private audio: ElementRef;
  @ViewChild('volumeMeter') volumeMeter: ElementRef;
  @ViewChild('audioVolume') private audioVolume: ElementRef;
  @Output() videoSettingToggled: EventEmitter<ParticipantVideoSetupViewComponent> = new EventEmitter<ParticipantVideoSetupViewComponent>();
  @Output() audioSettingToggled: EventEmitter<ParticipantVideoSetupViewComponent> = new EventEmitter<ParticipantVideoSetupViewComponent>();
  volumeLevel: number = undefined;

  constructor(@Host() private selectMediaHost: SelectMediaComponent, private audioProcessingService: AudioProcessingService,private meetingHandler: MeetingHandlerService) { }
  ngOnDestroy(): void {   
    if(this.videoStream){
      this.videoStream.getVideoTracks().forEach((track) => {
        track.stop();
      });
      this.videoStream = null;
    }
    if(this.audioStream){
      this.audioStream.getAudioTracks().forEach((track) => {
        track.stop();
      });

      //if(!this.selectMediaHost.inMeeting){
        this.audioProcessingService.endMonitorAudioLevel(MeetingHandlerService.previewAudioLevelId);
      //}
    }
  }
  ngOnInit(): void {
    //debugger;
    this.audioProcessingService.audioLevelReportedSubject.subscribe((params) => {
      const {participantSocketId, volumeLevel} = params;
      //console.log('here 1', volumeLevel);
      if(participantSocketId === MeetingHandlerService.previewAudioLevelId){
        this.audioLevelObserver(volumeLevel);
      }
    })
  }
  
  toggleVideoEnabled(){
    //this.videoEnabled = !this.videoEnabled;
    //this.displayVideo();
    this.videoSettingToggled.emit(this);
  }

  toggleAudioEnabled(){
    //this.audioEnabled = !this.audioEnabled;
    //this.displayAudio();
    this.audioSettingToggled.emit(this);
  }

  displayAudio(){
    if(this.audioEnabled){
      this.audio.nativeElement.srcObject = this.audioStream;
    }
    else{
      this.audio.nativeElement.srcObject = null;
    }
  }

  displayVideo(){
    this.video.nativeElement.srcObject = this.videoEnabled ? this.videoStream : null;    
  }

  private getCanvasStream(videoConstraints, selectedBackgroundImage, sameCamera){
    return this.meetingHandler.getCanvasStream(videoConstraints, selectedBackgroundImage, !this.inMeeting || (!this.meetingHandler.videoAvailable && this.inMeeting) || !sameCamera);
  }

  async setVideoStream(videoConstraints: any, selectedBackgroundImage, sameCamera){
    try{
      this.videoStream = selectedBackgroundImage ? this.getCanvasStream(videoConstraints, selectedBackgroundImage, sameCamera) : await navigator.mediaDevices.getUserMedia({video: videoConstraints});
      this.videoEnabled = this.videoStream ? this.videoStream.getVideoTracks().length > 0 : false;  
      this.displayVideo();
      this.video.nativeElement.muted = true;
      return this.videoStream;
    }
    catch(e){
      console.warn('error setting up video stream on settings page:', e)
    }
  }

  async setAudioStream(stream){
    this.audioStream = stream;
    this.displayAudio();
    await this.monitorAudioLevel();
  }

  async monitorAudioLevel(){
    if(this.audioStream){
      //using an empty participant id because it's for the current user
      await this.audioProcessingService.monitorAudioLevel(MeetingHandlerService.previewAudioLevelId, this.audioStream);
    }
    else{
      this.audioProcessingService.endMonitorAudioLevel(MeetingHandlerService.previewAudioLevelId);
    }
  }

  audioLevelObserver(volumeLevel: number){
    this.volumeLevel = volumeLevel;
    //[ngStyle]="{width: (this.volumeLevel === undefined || !audioEnabled ? 0 : this.volumeLevel) + '%'}"
    if(this.volumeLevel === undefined || !this.audioEnabled || this.volumeLevel < AudioProcessingService.minimumAudioLevelOfNote){//if it's very low or audio is not enabled, don't show it at all
      this.volumeMeter.nativeElement.style.width = `0px`;
    }
    else{
      this.volumeMeter.nativeElement.style.width = `${this.volumeLevel}%`;
    }
    
    //this.ref.detectChanges();
  }

  muteLocally(muted:boolean){
    this.audio.nativeElement.muted = muted;
  }
}
